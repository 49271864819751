.terms-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: flex-start;
    justify-content: flex-start;  
    text-align: start;
    padding-left: 25%;
    padding-right: 25%;
}

@media (max-width: 1100px) {
    .terms-container {
        padding-left: 5%;
        padding-right: 5%;
    }
}