.chart-stack {
    display: grid;
    padding-bottom: 50px;
}

.chart-stack > * {
    grid-row: 1;
    grid-column: 1;
}

.stickman {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 100px 0 100px 0;
  }