.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

@media only screen and (max-width: 600px) {
  .App {
    text-align: start;
    padding: 5%;
  }
}