.navBar {
    width: 100vw;
}

.navBar-inner {
    display: flex;
    flex-direction: row;
    align-items: center;   
    justify-content: space-between;
    padding: 2% 5% 2% 5%;
}

.phone {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.phone:hover {
    cursor: pointer;
}

.navBar-right {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 600px) {
    .navBar {
        width: 100%;
    }

    .navBar-inner {
        padding: 5%;
    }
}
