.logo:hover {
  cursor: pointer;
}

.title {
    font-weight: bold;
    font-size: 2em;
    letter-spacing: 10px;
    color: #6BB53A;
}

.subtitle {
    letter-spacing: 5px;
}

@media only screen and (max-width: 600px) {
    .title {
      font-size: 1.5em;
    }
  }