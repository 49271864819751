.health-title {
    display: flex;
    flex-direction: row;
}

.health-cards {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: flex-start;
}

.health-cards-1 {
    display: flex;
    flex-direction: column;
}

.health-cards-2 {
    display: flex;
    flex-direction: column;
}

.health-cards-3 {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.health-cards-3-inner {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.health-cards-3-inner1 {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 600px) {
    .health-title {

    }

    .health-cards {
        width: 100%;
        overflow: scroll;
    }

    .health-cards-3 {
        width: auto;
    }
}