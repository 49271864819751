.App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 5% 0 5%;
  }
  
  .section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 50px;
  }
  
  .front-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 4em;
  }
  
  .front-buttons {
    display: flex;
    flex-direction: row;
    padding: 100px 0 100px 0;
    align-items: center;
    justify-content: space-around;
    width: 90vw;
  }
  
  .holistic {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 50px;
  }
  
  .holistic-chart {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
  
  .finance {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .finance-desc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 40px;
  }
  
  @media only screen and (max-width: 600px) {
  
    .front-text {
      font-size: 2em;
    }
  
    .front-buttons {
      padding: 50px 0 50px 0;
      margin-bottom: 0 !important;
    }
  }