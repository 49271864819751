.privacy-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: flex-start;
    justify-content: flex-start;  
    padding-left: 25%;
    padding-right: 25%;
    text-align: start;
}

.privacy-text {
    text-align: left;
}

@media (max-width: 1100px) {
    .privacy-container {
        padding-left: 5%;
        padding-right: 5%;
    }
}