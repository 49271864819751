.professionals {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.professionals-inner {
    display: flex;
    flex-direction: column;
    width: 100vw;
}

.professionals-inner > * {
    margin: 20px 30% 20px 30% !important;
}

.MuiTelInput-Menu {
    transition: none;
}

@media only screen and (max-width: 600px) {
    .professionals {
        padding: 5%;
    }

    .professionals-inner > * {
        margin: 5% 10% 5% 10% !important;
    }

}