.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2% 5% 2% 5%;
}

.footer-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

@media only screen and (max-width: 600px) {
    .footer {
        flex-direction: column;
    }

    .footer-buttons {
        width: inherit;
        padding: 5% 0 5% 0;
        flex-wrap: wrap;
    }
}